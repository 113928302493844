// import React,{useState} from "react";
// import { Box, Button, Typography } from "@mui/material";
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
// import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
// import AddIcon from '@mui/icons-material/Add';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import ShareIcon from '@mui/icons-material/Share';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../../api/Source.api";

// function AdditionalOptionForLastConversationForTrackWebsite({prompt}) {
//   const {setIswebTrackClicked,setCurrentWebsiteLinkedId,setShowSuggested,clearThread,setThreadFiles,setHistoryFiles,setThreadId,setCurrentThread,setEditClick} = useWorkspace();
//   const token = localStorage.getItem("token")

//   const decodeUnicode = (str) => {
//     // Ensure str is a valid string, if not, return an empty string or handle it as needed
//     if (!str || typeof str !== "string") return str || "";

//     // Handle new line replacements
//     const stringNewLine = str.split("\\n").join("\n");

//     // Handle unicode characters
//     let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
//       const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
//       return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
//     });

//     // Remove any remaining backslashes
//     const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');

//     return finalString;
//   };


//   // Function to decode HTML entities
//   const decodeContent = (content) => {
//     return decodeUnicode((content));
//   };

//   const decodedContent = decodeContent(prompt);


//   const [copy, setCopy] = useState(false);

//   const copyFunc = () => {
//     // Copy decodedContent to clipboard
//     navigator.clipboard.writeText(decodedContent).then(() => {
//       setCopy(true); // Set copy to true to indicate the content has been copied
//       setTimeout(() => setCopy(false), 2000); // Reset after 2 seconds
//     });
//   };

//   const createThreadId = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/threads`, {
//         method: "POST",
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${token}`
//         },
//       });

//       const responseData = await response.json();

//       if (responseData.status) {
//         const newThreadId = responseData.data.match(/(.*)/)[1];
//         setThreadId(newThreadId);
//         localStorage.setItem('threadId', newThreadId); // Store the new threadId in localStorage
//         return newThreadId;
//       } else {
//         throw new Error("Thread creation failed");
//       }
//     } catch (error) {
//       console.error("Thread creation error:", error);
//       return null;
//     }
//   }

//   const ContinueChat = async(prompt) => {
//     setIswebTrackClicked(false);
//     setCurrentWebsiteLinkedId(null);
//     setShowSuggested(true)
//     clearThread();
//     setThreadFiles([]);
//     setHistoryFiles([]);
//     localStorage.removeItem("current_thread_id");
//     localStorage.removeItem("current_history_id");
//     const newThreadId = await createThreadId();
//     setThreadId(newThreadId);
//     const latestEntry = { sender: "user", content: prompt };
//     setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//     setEditClick(true);
//   }

//   return (
//     <div>
//       <Box
//         sx={{
//           width: "600px",
//           height: "48px",
//           marginTop: "12px",
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         {/* <Box
//           sx={{
//             width: "108px",
//             height: "48px",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <Box
//             sx={{
//               width: "48px",
//               height: "48px",
//               borderRadius: "50%",
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//             }}
//           ><ThumbUpOffAltIcon/>

//           </Box>

//           <Box
//             sx={{
//               width: "48px",
//               height: "48px",
//               borderRadius: "50%",
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//             }}
//           >
//             <ThumbDownOffAltIcon/>
//           </Box>
//         </Box> */}
//         <Box
//           sx={{
//             width: "276px",
//             height: "48px",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <Button
//             sx={{
//               width: "156px",
//               height: "48px",
//               borderRadius: "24px",
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: 'space-evenly',
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//               textTransform:'none'
//             }}
//             onClick={() => ContinueChat(prompt)}
//           ><AddIcon sx={{color: "#CAC4D0"}}/>
//           <Typography sx={{
//             fontFamily: "Inter",
//             fontSize: "14px",
//             fontWeight: "500",
//             textAlign: "left",
//             color: "#CAC4D0",
//           }}>Create project</Typography>

//           </Button>

//           <Box
//            onClick={() => copyFunc()}
//             sx={{
//               width: "48px",
//               height: "48px",
//               borderRadius : '50%',
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//             }}
//           >
//             <ContentCopyIcon/>
//           </Box>
//           <Box
//             onClick={() => alert("Coming soon!..")}
//             sx={{
//               width: "48px",
//               height: "48px",
//               borderRadius : '50%',
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//             }}
//           >
//             <ShareIcon/>
//           </Box>
//         </Box>
//       </Box>
//     </div>
//   );
// }

// export default AdditionalOptionForLastConversationForTrackWebsite;


import React, { useState } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from "../../../../api/Source.api";

function AdditionalOptionForLastConversationForTrackWebsite({ prompt }) {
  const { setIswebTrackClicked, setCurrentWebsiteLinkedId, setShowSuggested, clearThread, setThreadFiles, setHistoryFiles, setThreadId, setCurrentThread, setEditClick } = useWorkspace();
  const token = localStorage.getItem("token");

  const decodeUnicode = (str) => {
    if (!str || typeof str !== "string") return str || "";
    const stringNewLine = str.split("\\n").join("\n");
    let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
      const hexCode = match.replace(/\\?u/g, ''); 
      return String.fromCharCode(parseInt(hexCode, 16));
    });
    return stringNewLineWithAnchorTag.replace(/\\/g, '');
  };

  const decodeContent = (content) => decodeUnicode(content);
  const decodedContent = decodeContent(prompt);

  const [copy, setCopy] = useState(false);
  const [projectTooltip, setProjectTooltip] = useState("Create a new project based on this information");
  const [shareTooltip, setShareTooltip] = useState("Share to feed");

  const copyFunc = () => {
    navigator.clipboard.writeText(decodedContent).then(() => {
      setCopy(true);
      setTimeout(() => setCopy(false), 2000);
    });
  };

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      const responseData = await response.json();
      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem('threadId', newThreadId);
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      console.error("Thread creation error:", error);
      return null;
    }
  };

  const ContinueChat = async (prompt) => {
    setIswebTrackClicked(false);
    // setCurrentWebsiteLinkedId(null);
    setShowSuggested(true);
    clearThread();
    setThreadFiles([]);
    setHistoryFiles([]);
    localStorage.removeItem("current_thread_id");
    localStorage.removeItem("current_history_id");
    const newThreadId = await createThreadId();
    setThreadId(newThreadId);
    const latestEntry = { sender: "user", content: prompt };
    setCurrentThread((prevThread) => [...prevThread, latestEntry]);
    setEditClick(true);
    setProjectTooltip(""); // Clear tooltip after click
  };

  return (
    <div>
      <Box
        sx={{
          width: "600px",
          height: "48px",
          marginTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Box
          sx={{
            width: "108px",
            height: "48px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              bgcolor: "#1F233E",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#282D51",
                cursor: "pointer"
              },
            }}
          ><ThumbUpOffAltIcon/>
          </Box>
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              bgcolor: "#1F233E",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#282D51",
                cursor: "pointer"
              },
            }}
          >
            <ThumbDownOffAltIcon/>
          </Box>
        </Box> */}
        <Box
          sx={{
            width: "276px",
            height: "48px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tooltip title={projectTooltip} arrow>
            <Button
              sx={{
                width: "156px",
                height: "48px",
                borderRadius: "24px",
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: 'space-evenly',
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
                textTransform: 'none'
              }}
              onClick={() => ContinueChat(prompt)}
            >
              <AddIcon sx={{ color: "#CAC4D0" }} />
              <Typography sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "left",
                color: "#CAC4D0",
              }}>Create project</Typography>
            </Button>
          </Tooltip>

          <Tooltip title={copy ? "Copied" : "Copy"} arrow>
            <Box
              onClick={copyFunc}
              sx={{
                width: "48px",
                height: "48px",
                borderRadius: '50%',
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
              }}
            >
              <ContentCopyIcon />
            </Box>
          </Tooltip>

          <Tooltip title={shareTooltip} arrow>
            <Box
              onClick={() => {
                alert("Coming soon!..");
                setShareTooltip(""); // Clear tooltip after click
              }}
              sx={{
                width: "48px",
                height: "48px",
                borderRadius: '50%',
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
              }}
            >
              <ShareIcon />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
}

export default AdditionalOptionForLastConversationForTrackWebsite;
