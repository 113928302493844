// import React, { useState } from "react";
// import { Container, Modal, Row, Col, Card, Image } from "react-bootstrap";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import PreviewMedia from "./PreviewMedia"; // Assuming PreviewMedia component is separated
// import promptExamples from "../../../constants/promptExamples";
// import MessageCard from "./MessageCard";
// import GetLogo from "../../GetLogo";
// // import sampleThread from "../../../variables/sampleThread";

// const Messages = () => {
//   const { currentThread } = useWorkspace();
//   const [showPreview, setShowPreview] = useState(false);
//   const [activeMessage, setActiveMessage] = useState(null);
//   // const currentThread = sampleThread;
//   const logoSrc = GetLogo();

//   const handleDownload = (link) => {
//     window.open(link, "_blank");
//   };

//   const handlePreview = (url) => {
//     setActiveMessage(url);
//     setShowPreview(true);
//   };

//   // The main rendering logic including the Modal for previews remains here
//   return (
//     <Container
//       fluid
//       style={{
//         height: "95vh",
//         overflow: "auto",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: currentThread.length === 0 ? "flex-end" : "flex-start",
//       }}
//       className="py-2"
//     >
//       {/* // Render prompt examples if the current thread is empty */}
//       {currentThread.length === 0 && (
//         <>
//           <Row className="mb-5 text-center">
//             <Image
//               src={logoSrc}
//               loading="lazy"
//               alt="Company Logo"
//               style={{ width: "100px", display: "block", margin: "0 auto" }}
//               className="mb-3"
//             />
//             <h1 className="mb-2 text-secondary">
//               What would you like to do today?
//             </h1>
//           </Row>
//           <Row sm={1} md={2} className="pb-3 align-items-stretch">
//             {promptExamples.map((item, index) => (
//               <Col key={index}>
//                 <Card className="mb-3" >
//                   <Card.Body>
//                     <Card.Subtitle className="mb-2 text-muted">
//                       {item.subtitle}
//                     </Card.Subtitle>
//                     <Card.Text>{item.text}</Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ))}
//           </Row>
//         </>
//       )}

//       {/* // Render the messages */}
//       {currentThread.map((message, index) => (
//         <MessageCard
//           key={index}
//           message={message}
//           handleDownload={handleDownload}
//           handlePreview={handlePreview}
//         />
//       ))}

//       {/* // Render the preview modal */}
//       {showPreview && (
//         <Modal
//           size="lg"
//           show={showPreview}
//           onHide={() => {
//             setShowPreview(false);
//             setActiveMessage(null);
//           }}
//         >
//           {" "}
//           <Modal.Header closeButton>
//             <Modal.Title>Preview</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <PreviewMedia url={activeMessage} />{" "}
//           </Modal.Body>
//         </Modal>
//       )}
//     </Container>
//   );
// };

// export default Messages;

import React, { useState, useEffect,useRef  } from "react";
import { Container, Modal, Button, Card, Row, Col, Image, ProgressBar } from "react-bootstrap";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import PreviewMedia from "./PreviewMedia"; 
import promptExamples from "../../../constants/promptExamples";
import MessageCard from "./MessageCard";
import TypingIndicator from "./TypingIndicator";
import { uploadFiles } from "../../../contexts/WorkspaceUtils";
import FileUploader from "../settingBar/FileUploader";
import  ServicesForDefaultWindow  from "./ServicesForDefaultWindow";
import axios from "axios";
import sourceUrl from "../../../api/Source.api";
import GetModalX_FlowDiagram from "../../GetModalX_FlowDiagram";
import Default_UserInfo from "./UserInfo/Default_UserInfo";
import User_Challenges from "./UserInfo/User_Challenges";
import AdditionalOptionForLastConversation from "./AdditionalOptionForLastConversation";
import EnhancePrompt from "./EnhancePrompt/EnhancePrompt";
import {useMediaQuery} from '@mui/material'
import GetModalXFlowDiagramForMobileResponsive from "../mobileResponsive/GetModalXFlowDiagramForMobileResponsive";
import { ButtonClick, LikeDislikeEvent } from "../../../config/analytics";
import RenderSourcesToMessageCard from "./RenderSourcesToMessageCard";

const Messages = () => {
  const { currentHistoryId,setCurrentHistoryId,InvokeMatrixApi,threadId,setHistoryFiles,setThreadId,sending,setSending,checkUrlStatus, currentThread, waitThread, setWaitThread, setCurrentThread, sendMessage, threadFiles,Today,Yesterday,ThisWeek,ThisMonth,PrevMonth,editClick } = useWorkspace();
  const messagesEndRef = useRef(null);

  const [showPreview, setShowPreview] = useState(false);
  const [activeMessage, setActiveMessage] = useState(null);
  const [input, setInput] = useState("");
  const [currPromptId,setCurrPromptId] = useState(null);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fileUploads, setFileUploads] = useState([]);
  const [fileProcessing,setFileProcessing] = useState(false);
  const [numOfResp,setNumOfResp] = useState(0);
  const token  = localStorage.getItem('token');
  // let numOfResp = 0 ;
  let countResp = 0 ;
  const mobileView = useMediaQuery('(max-width:992px)');


  const extractNumber = (text) => {
    const prefix = "thread-";
    if (text.startsWith(prefix)) {
      const numberPart = text.slice(prefix.length);
      return parseInt(numberPart, 10); // Convert the extracted part to a number
    }
    return null;
  };

  const handleHistoryDataFetch = async (thread_id_from_localstorage, current_history_id_from_localstorage) => {
    if (!thread_id_from_localstorage || !current_history_id_from_localstorage) {
      console.error('Invalid thread ID or history ID');
      return;
    }
  
    const id = current_history_id_from_localstorage;
    const thread_id = extractNumber(thread_id_from_localstorage);
    if (thread_id === null) {
      console.error('Invalid thread ID format');
      return;
    }
  
    try {
      const res = await axios.get(`${sourceUrl}/chat/${id}/history`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      // ==================
      setThreadId(`thread-${thread_id}`)
      setCurrentHistoryId(localStorage.getItem('current_history_id'));
      // ==================
      setCurrentThread(res.data.data.history);
      
      if (res.data.data && res.data.data.input_files && res.data.data.input_files.length) {
        setHistoryFiles(res.data.data.input_files)
      } else {
        setHistoryFiles([])
      }
      // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
      try {
        const data = {
          url: 'history',
          threadId: threadId,
          requestId: res.data.requestID,
          requestType: 'GET',
          requestBody: {},
          responseBody: {},
          responseCode: res.status
        };
  
        const headers = {
          'Content-Type': 'application/json'
        };
  
        const matrix_Res = await axios.post(`${sourceUrl}/add`, data, { headers });
      } catch (error) {
        console.error('Error making the POST request:', error);
      }
      // await InvokeMatrixApi(res, res, 'GET', threadId, 'history', {});
      // ===============================================================================================
      
      // console.log("here is the updated curr_thread", currentThread);
    } catch (error) {
      console.log(error);
      // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
      await InvokeMatrixApi(error, error.data, 'GET', threadId, 'summary', {});
      // ===============================================================================================
    }
  }
  

  useEffect(() => {
    const thread_id_from_localstorage = localStorage.getItem('current_thread_id');
    const current_history_id_from_localstorage = localStorage.getItem('current_history_id');
    if (thread_id_from_localstorage !== '' && current_history_id_from_localstorage !== '') {
      handleHistoryDataFetch(thread_id_from_localstorage, current_history_id_from_localstorage);
    } else {
      setCurrentThread([]); // clear the current thread if no valid data is found
    }
  }, []);
  
  // useEffect(() => {
  //   if(localStorage.getItem('current_thread_id') !== ''){
  //     const thread_id_from_localstorage = localStorage.getItem('current_thread_id');
  //     const current_history_id_from_localstorage = localStorage.getItem('current_history_id');
  //     handleHistoryDataFetch(thread_id_from_localstorage,current_history_id_from_localstorage);
  //   }
  // }, [])
  

  useEffect(() => {
    setFiles(threadFiles);
  }, [threadFiles]);

  useEffect(() => {
    // let intervalId;
  
    // const checkFileStatus = async () => {
    //   const processedFiles = await Promise.all(
    //     files.map(async (file) => {
    //       const url = file.url ;
    //       const fileId = file.fileId ;
    //       // const res = await checkUrlStatus(file.url);
    //       // return res;
    //       const res = await fetch("https://www.modalx.ai/status", {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           "Authorization":`Bearer ${token}`
    //         },
    //         body: JSON.stringify({ fileId, url }),
    //       });
    //       const responseData = await res.json();
    //       return res.status === 200 || res.status === 500 ;
          
    //     })
    //   );
  
    //   const allFilesProcessed = processedFiles.every((res) => res);
      
    //   if (allFilesProcessed) {
    //     clearInterval(intervalId);
    //   }
      
    //   setFileProcessing(allFilesProcessed);
    // };
  
    // // Run once initially
    // checkFileStatus();
  
    // // Set interval to periodically check file status
    // intervalId = setInterval(checkFileStatus, 3000);
  
    // return () => {
    //   clearInterval(intervalId);
    //   setFileProcessing(false);
    // };
    const isAnyFileProcessing = files.some(file => file && file.status === 'processing');
    setFileProcessing(isAnyFileProcessing);
  }, [files]);
  
  useEffect(() => {
    // Scroll to the bottom of the container whenever currentThread changes
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentThread]);
  

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };

  const handlePreview = (message) => {
    setActiveMessage(message);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setActiveMessage(null);
  };

  const handleGenerate = (item) => {
    setInput(item.subtitle + " " + item.text);
    setCurrPromptId(item.id)
  };

  useEffect(() => {
    handleSubmit(input)
  }, [currPromptId === 1 || currPromptId === 2])
  
  const handleInstantGenerate = (item) => {
    if (item.id === 3) {
      setShowModal(true);
      setInput('Shrink the below transcribed content from various sources, such as videos, audio recordings, and PDFs, within 3000 words and also keep the points small that can be used to create a ppt summary');
    } else if (item.id === 4) {
      setShowModal(true);
      setInput('Shrink the below transcribed content from various sources, such as videos, audio recordings, and PDFs, within 3000 words and also keep the points small that can be used to create a image illustraions');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSend = () => {
    handleSubmit(input);
    setShowModal(false);
  };
  
  console.log("file_uploads",fileUploads);
  const handleSubmit = async (input) => {
    setWaitThread(true);
    if (input.trim()) {
      const latestEntry = { sender: "user", content: input };
      setSending(true);
      setInput("");
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      if (entryResponse) {
        setSending(false)
        setCurrentThread((prevThread) => [...prevThread, entryResponse]);
      } else {
        setSending(false);
        setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
      }
    }
    setWaitThread(false);
  };

  const handleFileUpload = async (files) => {
    
    // Map files to upload promises
    const uploadPromises = files.map(async (file) => {
      
      // Upload the file
      const response = await uploadFiles(file);
      const fileId = response.data.id;
      const url = response.data.url;
      
      // Return file details with upload status
      return {
        ...file,
        fileId: fileId,
        url: url,
        status: response.data.status,
      };
    });

    // Wait for all uploads to finish
    const uploadedFiles = await Promise.all(uploadPromises);

    // Update file uploads state
    setFileUploads(uploadedFiles);
    
  }

  const isAnyFileUploading = fileUploads.some(file => file.status === 'processing');

  const theme = localStorage.getItem("theme") || "light";

  const containsChallenges = (content) => {
    const lowerCaseContent = content.toLowerCase();
    return (
      lowerCaseContent.includes("some") ||
      lowerCaseContent.includes("few") ||
      lowerCaseContent.includes("challenges")
    );
  };

  // console.log("99999999999999999999999999",numOfResp);

  useEffect(() => {
    let newNumOfResp = 0;

    currentThread.forEach(message => {
      if (message?.user && message?.agent) {
        newNumOfResp += 2;
      } else if (message?.sender) {
        newNumOfResp += 1;
      }
    });

    setNumOfResp(newNumOfResp);

  }, [currentThread]);

  // alert(currentThread.length) 

  const handleUserAction = (id, chat_id, action) => {
    // console.log("oooooooooooooooooooooooooooooooooooooooooooooooo",id)
    const url = `${sourceUrl}/threads/${threadId}/userAction`;
    axios.post(url, {
      id: id,
      chat_id: parseInt(chat_id, 10),
      action: action,
    }, {
      headers: {
        'accept': '*/*',
        'authorization': `Bearer ${token}`,
        'content-type': 'application/json',
      }
    })
      .then(response => {
        console.log('Success:', response.data);
        LikeDislikeEvent("Like Dislike Event", "Like Dislike Button", action,  response.data)
      })
      .catch(error => {
        console.error('Error:', error);
        LikeDislikeEvent("Like Dislike Event", "Like Dislike Button", action,  error)
      });
  };

  function extractUrlsExceptFileTypeUrls(markdown) {
    if (typeof markdown !== 'string') {
        console.error('Expected a string input for markdown content');
        return [];
    }

    const urlRegex = /\bhttps?:\/\/[^\s)\\]+/gi;
    const fileExtensionsToExclude = [
      '.pdf',  // PDF files
      '.ppt',  // PowerPoint presentations
      '.pptx', // PowerPoint presentations
      '.doc',  // Word documents
      '.docx', // Word documents
      '.xls',  // Excel spreadsheets
      '.xlsx', // Excel spreadsheets
      '.txt',  // Text files
      '.csv',  // Comma-separated values
      '.xml',  // XML files
      '.json', // JSON files
      '.mp3',  // Audio files
      '.m4a',  // Audio files
      '.mp4',  // Video files
      '.avi',  // Video files
      '.mkv',  // Video files
      '.mov',  // QuickTime video files
      '.svg',  // Scalable Vector Graphics
      '.jpg',  // JPEG images
      '.jpeg', // JPEG images
      '.png',  // PNG images
  ];

    // Extract all URLs
    const urls = markdown.match(urlRegex) || [];

    // Filter out URLs with unwanted file extensions
    const filteredUrls = urls.filter(url => {
        return !fileExtensionsToExclude.some(ext => url.toLowerCase().endsWith(ext));
    });

    // Map URLs to an object with ids and clean up any trailing characters
    return filteredUrls.map((url, index) => ({
        id: index + 1,
        url: url.replace(/[)\\n]+$/, '') // Remove trailing ) and \n characters
    }));
}
  
  return (
    <>
      <style>
        {`
          /* Custom scrollbar styles for the container */
          .py-2::-webkit-scrollbar {
            width: 10px;
          }

          .py-2::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 5px;
            height: 60px;
          }
        `}
      </style>

        <Container fluid 
        style={{ height: "95vh", overflow: "auto", display: "flex", flexDirection: "column", justifyContent: currentThread.length === 0 ? "flex-end" : "flex-start"}} className="py-2">
      {currentThread.length === 0 && (
        <>
          <div style={{margin:'auto'}}>
            {/* {mobileView ? '' : <p style={{ fontSize: '16px',marginLeft:'12%'}}>Give us your context by uploading multiple files or links at once</p> } */}
          {/* <p style={{ fontSize: '16px',marginLeft:'12%'}}>Give us your context by uploading multiple files or links at once</p> */}
            {/* <Image
              src={GetModalX_FlowDiagram()}
              loading="lazy"
              alt="Company Logo"
              style={{display: "block", margin: "0 auto", scale: '1',bottom:'0',maxHeight:'774px',maxWidth:'609px'}}
              className="mb-3"
            />  */}
            {mobileView ? <GetModalXFlowDiagramForMobileResponsive/> : 
                        <div style={{ margin: 'auto'}}>
                        <GetModalX_FlowDiagram />
                      </div>
            }

          </div>
          {/* <div style={{marginTop:'10px'}}>
            <ServicesForDefaultWindow />
          </div>
          <p style={{margin:'auto'}}>Generate a summary of your content  in One-Click in any of the above formats</p> */}
          {/* <Row sm={1} md={2} className="pb-3 align-items-stretch">
            {promptExamples.map((item, index) => (
              <Col key={index}>
                <Card
                  className="mb-3"
                  onClick={() => (item.id === 1 || item.id === 2 ? handleGenerate(item) : handleInstantGenerate(item))}
                  style={{ cursor: "pointer", transition: "background-color 0.3s ease",minHeight:'140px' }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = theme === "dark" ? "#595959" : "#ccc6c6";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "inherit";
                  }}
                >
                  <Card.Body style={{ fontSize: ".9rem",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                    <Card.Subtitle className="mb-2 text-muted">{item.subtitle}</Card.Subtitle>
                    <Card.Text style={{ color: theme === "dark" ? "#b5b5b3" : ""}}>{item.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row> */}
        </>
      )}

      {/* {currentThread.map((message, index) => {

        const contentToCheck = message?.content || message?.agent;

        // Correctly checking for multiple possible phrases based on either content or agent
        const isUserInfoMessage =
          contentToCheck?.includes("Company Name:") &&
          (contentToCheck?.includes("Role of the Person:") || contentToCheck?.includes("Role")) &&
          (contentToCheck?.includes("Company Details:") || contentToCheck?.includes("Company Info"));

        return isUserInfoMessage ? (
          <Default_UserInfo key={index} message={message} />
        ) : (
          <MessageCard
            key={index}
            message={message}
            handleDownload={handleDownload}
            handlePreview={handlePreview}
          />
        );
      })} */}

      {currentThread.map((message, index) => {

        const contentToCheck = message?.content || message?.agent;
        const urlsExceptFileType = extractUrlsExceptFileTypeUrls(message?.sender === "modalX"? message?.content : message?.agent);
        // Function to check if the content contains "some", "few" and "challenges"
        const containsChallenges = (content) => {
          const lowerCaseContent = content?.toLowerCase();
          return (
            // (lowerCaseContent.includes("some") || lowerCaseContent.includes("few")) &&
            lowerCaseContent.includes("challenges")
          );
        };
        if(message?.user && message?.agent){
          countResp += 2 ;
        }
        else if(message?.sender){
          countResp += 1;
        }

        const isUserInfoMessage =
          contentToCheck?.includes("Company Name") &&
          (contentToCheck?.includes("Role of the Person:") || contentToCheck?.includes("Role")) &&
          (contentToCheck?.includes("Company Details:") || contentToCheck?.includes("Company Info"));

        return (
          <React.Fragment key={index}>
            {isUserInfoMessage ? (
              <Default_UserInfo message={message} numOfResp={numOfResp} />
            ) : 
            // containsChallenges(contentToCheck) && (message?.sender === "modalX" && index <= 3) ||  message?.agent && index === 1 ? 
            containsChallenges(contentToCheck) && countResp <=4 
            && ((Today?.length <= 1 && (Yesterday?.length === 0 && ThisWeek?.length === 0 && ThisMonth?.length === 0 && PrevMonth?.length === 0)) || (Yesterday?.length <= 1 && (Today?.length === 0 && ThisWeek?.length === 0 && ThisMonth?.length === 0 && PrevMonth?.length === 0)) || (ThisWeek?.length <= 1 && (Today?.length === 0 && Yesterday?.length === 0 && ThisMonth?.length === 0 && PrevMonth?.length === 0)) || (ThisMonth?.length <= 1 && (Today?.length === 0 && Yesterday?.length === 0 && ThisWeek?.length === 0 && PrevMonth?.length === 0)) || (PrevMonth?.length <= 1 && (Today?.length === 0 && Yesterday?.length === 0 && ThisWeek?.length === 0 && ThisMonth?.length === 0 )))
            ?
            (
              <User_Challenges message={message} />
            ) : (
              <div>
                <MessageCard
                message={message}
                handleDownload={handleDownload}
                handlePreview={handlePreview}
                index={index}
              />
              {((message?.sender === "modalX" || message?.agent) && urlsExceptFileType?.length > 0 ) && <RenderSourcesToMessageCard sources={urlsExceptFileType} />}
              </div>
              
              
            )}
            {!editClick && index === currentThread.length - 1 && <AdditionalOptionForLastConversation message={message} onUserAction={(action) => handleUserAction(message.id,currentHistoryId,action)}/>}
            {/* {index === currentThread.length - 1 && <EnhancePrompt message={message} />} */}
          </React.Fragment>
        );
      })}



      {sending && (
        <p style={{ textAlign: "end" }}>
          <TypingIndicator />
        </p>
      )}

      <Modal size="lg" show={showPreview} onHide={handleClosePreview} centered>
        <Modal.Header closeButton>
          <Modal.Title>{activeMessage && activeMessage.subtitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{activeMessage && activeMessage.text}</p>
          {activeMessage && activeMessage.url && <PreviewMedia url={activeMessage.url} />}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClosePreview}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <h6>Prompt :</h6>
          <p>{input}</p>
          <FileUploader onFileUpload={handleFileUpload} />

          {/* Display prompt text */}
          

          {/* Display files or message if no files uploaded */}
          <h6 style={{ color: 'grey' }}>{files.length ? "Files :" : "No files uploaded yet"}</h6>
          {files.length ? (
            <div>
              <ul>
                {files.map((file, index) => (
                  <>
                    <li key={index}>{file.name}</li>
                  </>
                ))}
              </ul>
            </div>
          ) : (
            <p></p>
          )}

          {/* Render progress bars */}
          {fileUploads.map((file, index) => (
            <div key={index}>
              {file.status === "uploading" && (
                <ProgressBar animated now={100} label="Uploading..." />
              )}
              {file.status === "uploaded" && (
                <ProgressBar now={100} label="Uploaded" />
              )}
              {file.status === "upload failed" && (
                <ProgressBar now={0} label="Upload failed" variant="danger" />
              )}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={handleSend} disabled={waitThread || fileProcessing}>Send</Button>
        </Modal.Footer>
      </Modal>
      <div ref={messagesEndRef} />
    </Container>
    </>

  );
};

export default Messages;


// [
//   {
//     "id":1,
//     "subtitle": "How can I become more productive",
//     "text": "Maximize productivity by mastering time management, energy management, and focus."
//   },
//   {
//     "id":2,
//     "subtitle": "Generate onboarding video",
//     "text": "Produce an engaging onboarding video introducing new team members to your company's culture, values."
//   },
//   {
//     "id":3,
//     "subtitle": "Generate review/sales presentation",
//     "text": "Power up your pitch: Transforming ideas into impactful presentations."
//   },
//   {
//     "id":4,
//     "subtitle": "Generate an image for a linkedin post",
//     "text": "Picture-perfect posts: Elevate your LinkedIn presence with captivating visuals."
//   }
// ]



// ================latest Messages.jsx below =============================================================

// import React, { useState, useEffect } from "react";
// import { Container, Modal, Button, Card, Row, Col, Image, ProgressBar } from "react-bootstrap";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import PreviewMedia from "./PreviewMedia"; 
// import promptExamples from "../../../constants/promptExamples";
// import MessageCard from "./MessageCard";
// import GetLogo from "../../GetLogo";
// import TypingIndicator from "./TypingIndicator";
// import { uploadFiles } from "../../../contexts/WorkspaceUtils";
// import FileUploader from "../settingBar/FileUploader";

// const Messages = () => {
//   const { checkUrlStatus, currentThread, waitThread, setWaitThread, setCurrentThread, sendMessage, threadFiles } = useWorkspace();

//   const [showPreview, setShowPreview] = useState(false);
//   const [activeMessage, setActiveMessage] = useState(null);
//   const [input, setInput] = useState("");
//   const [files, setFiles] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [fileUploads, setFileUploads] = useState([]);
//   const [fileProcessing,setFileProcessing] = useState(false);

  

//   useEffect(() => {
//     setFiles(threadFiles);
//   }, [threadFiles]);

//   useEffect(() => {
//     let intervalId;
  
//     const checkFileStatus = async () => {
//       const processedFiles = await Promise.all(
//         files.map(async (file) => {
//           const res = await checkUrlStatus(file.url);
//           return res;
//         })
//       );
  
//       const allFilesProcessed = processedFiles.every((res) => res);
  
//       if (allFilesProcessed) {
//         clearInterval(intervalId);
//       }
  
//       setFileProcessing(allFilesProcessed);
//     };
  
//     // Run once initially
//     checkFileStatus();
  
//     // Set interval to periodically check file status
//     intervalId = setInterval(checkFileStatus, 3000);
  
//     return () => {
//       clearInterval(intervalId);
//       setFileProcessing(false);
//     };
//   }, [files]);
  
  

//   const handleDownload = (link) => {
//     window.open(link, "_blank");
//   };

//   const handlePreview = (message) => {
//     setActiveMessage(message);
//     setShowPreview(true);
//   };

//   const handleClosePreview = () => {
//     setShowPreview(false);
//     setActiveMessage(null);
//   };

//   const handleGenerate = (item) => {
//     setInput(item.subtitle + " " + item.text);
//     handleSubmit(input);
//   };

//   const handleInstantGenerate = (item) => {
//     if (item.id === 3) {
//       setShowModal(true);
//       setInput('Shrink the below transcribed content from various sources, such as videos, audio recordings, and PDFs, within 3000 words and also keep the points small that can be used to create a ppt summary');
//     } else if (item.id === 4) {
//       setShowModal(true);
//       setInput('Shrink the below transcribed content from various sources, such as videos, audio recordings, and PDFs, within 3000 words and also keep the points small that can be used to create a image illustraions');
//     }
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleSend = () => {
//     handleSubmit(input);
//     setShowModal(false);
//   };

//   console.log("file_uploads",fileUploads);
//   const handleSubmit = async (input) => {
//     setWaitThread(true);
//     if (input.trim()) {
//       const latestEntry = { sender: "user", content: input };
//       setInput("");
//       setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//       const entryResponse = await sendMessage(latestEntry).catch(console.error);
//       if (entryResponse) {
//         setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//       } else {
//         setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
//       }
//     }
//     setWaitThread(false);
//   };

//   const handleFileUpload = async (files) => {
    
//     // Map files to upload promises
//     const uploadPromises = files.map(async (file) => {
      
//       // Upload the file
//       const response = await uploadFiles(file);
//       const fileId = response.data.id;
//       const url = response.data.url;
      
//       // Return file details with upload status
//       return {
//         ...file,
//         fileId: fileId,
//         url: url,
//         status: response.data.status,
//       };
//     });

//     // Wait for all uploads to finish
//     const uploadedFiles = await Promise.all(uploadPromises);

//     // Update file uploads state
//     setFileUploads(uploadedFiles);
    
//   };

//   const isAnyFileUploading = fileUploads.some(file => file.status === 'processing');

//   const theme = localStorage.getItem("theme") || "light";
  
//   return (
//     <Container fluid style={{ height: "95vh", overflow: "auto", display: "flex", flexDirection: "column", justifyContent: currentThread.length === 0 ? "flex-end" : "flex-start" }} className="py-2">
//       {currentThread.length === 0 && (
//         <>
//           <Row className="mb-5 text-center">
//             <Image
//               src={GetLogo()}
//               loading="lazy"
//               alt="Company Logo"
//               style={{ width: "100px", display: "block", margin: "0 auto", scale: '3' }}
//               className="mb-3"
//             />
//             <h1 className="mb-2 text-secondary" style={{ fontSize: '2rem' }}>What would you like to do today?</h1>
//           </Row>
//           <Row sm={1} md={2} className="pb-3 align-items-stretch">
//             {promptExamples.map((item, index) => (
//               <Col key={index}>
//                 <Card
//                   className="mb-3"
//                   onClick={() => (item.id === 1 || item.id === 2 ? handleGenerate(item) : handleInstantGenerate(item))}
//                   style={{ cursor: "pointer", transition: "background-color 0.3s ease" }}
//                   onMouseEnter={(e) => {
//                     e.currentTarget.style.backgroundColor = theme === "dark" ? "#595959" : "#ccc6c6";
//                   }}
//                   onMouseLeave={(e) => {
//                     e.currentTarget.style.backgroundColor = "inherit";
//                   }}
//                 >
//                   <Card.Body style={{ fontSize: ".9rem" }}>
//                     <Card.Subtitle className="mb-2 text-muted">{item.subtitle}</Card.Subtitle>
//                     <Card.Text style={{ color: theme === "dark" ? "#b5b5b3" : "" }}>{item.text}</Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ))}
//           </Row>
//         </>
//       )}

//       {currentThread.map((message, index) => (
//         <MessageCard key={index} message={message} handleDownload={handleDownload} handlePreview={handlePreview} />
//       ))}

//       {waitThread && (
//         <p style={{ textAlign: "end" }}>
//           <TypingIndicator />
//         </p>
//       )}

//       <Modal size="lg" show={showPreview} onHide={handleClosePreview} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>{activeMessage && activeMessage.subtitle}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>{activeMessage && activeMessage.text}</p>
//           {activeMessage && activeMessage.url && <PreviewMedia url={activeMessage.url} />}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button onClick={handleClosePreview}>Close</Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton />
//         <Modal.Body>
//           <h6>Prompt :</h6>
//           <p>{input}</p>
//           <FileUploader onFileUpload={handleFileUpload} />

//           {/* Display prompt text */}
          

//           {/* Display files or message if no files uploaded */}
//           <h6 style={{ color: 'grey' }}>{files.length ? "Files :" : "No files uploaded yet"}</h6>
//           {files.length ? (
//             <div>
//               <ul>
//                 {files.map((file, index) => (
//                   <>
//                     <li key={index}>{file.name}</li>
//                   </>
//                 ))}
//               </ul>
//             </div>
//           ) : (
//             <p></p>
//           )}

//           {/* Render progress bars */}
//           {fileUploads.map((file, index) => (
//             <div key={index}>
//               {file.status === "uploading" && (
//                 <ProgressBar animated now={100} label="Uploading..." />
//               )}
//               {file.status === "uploaded" && (
//                 <ProgressBar now={100} label="Uploaded" />
//               )}
//               {file.status === "upload failed" && (
//                 <ProgressBar now={0} label="Upload failed" variant="danger" />
//               )}
//             </div>
//           ))}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
//           <Button variant="primary" onClick={handleSend} disabled={!fileProcessing}>Send</Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default Messages;
