import React, { useState, useEffect } from "react";
import { button_bg_color_for_New_Project, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from '../../../../constants/colors'
import { Modal, Box, Typography, IconButton, Button, TextField, FormHelperText, InputLabel, MenuItem, Select, FormControl, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { New_Project_Button_bg_color } from "../../../../Provider/Color_Provider";
import successIcon from "../../../../assets/greencheckIcon.png";
import Info from "../../../../assets/info.png";
import axios from "axios";
import sourceUrl from "../../../../api/Source.api";
import ErrorIcon from '@mui/icons-material/Error';

const TrackWebsiteModal = ({onAddWebClicked}) => {

  const theme = localStorage.getItem('theme');
  const [window, setWindow] = useState(0);
  const [websitelink, setWebSiteLink] = useState("");
  const [saveas, setSaveAs] = useState("");
  const [checkforupdates, setCheckforupdates] = useState(15);
  const { openWebsiteTrackModal, setOpenWebsiteTrackModal } = useWorkspace()
  const token = localStorage.getItem('token')

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleNextButtonClick = () => {
    if (websitelink == "") {
      alert("Please Enter WebSite URL")
    } else {
      setWindow(1)
    }
  }

  useEffect(() => {
    function isValidUrl(url) {
      // Regular expression to check for a valid URL pattern
      const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
      return urlPattern.test(url);
    }
  
    function getMainSiteName(url) {
      // Remove protocol (http, https, etc.) and 'www.' prefix if present
      let domain = url.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '');
      // Set the main site name to state
      setSaveAs(domain.split('.')[0]);
    }
  
    // Run only if websitelink is a valid URL
    if (websitelink && isValidUrl(websitelink)) {
      getMainSiteName(websitelink);
    }
  }, [websitelink]);

  const handleAddWebsiteButtonClick = async (websiteLink, displayName, frequencyInMins) => {
    if(!saveas.length || !checkforupdates){
      alert("Please fill all the fileds.")
      return null;
    }
    const res = await InvokeAddWebsiteApi(websiteLink, displayName, frequencyInMins);
    if(res?.data?.status){
      setWindow(2);
      onAddWebClicked();
      // setOpenWebsiteTrackModal(false)
      setWebSiteLink('')
      setSaveAs('')
      setCheckforupdates('')
    }
  };
  
  const InvokeAddWebsiteApi = async (websiteLink, displayName, frequencyInMins) => {
    try {
      const response = await axios.post(
        `${sourceUrl}/websiteTracking/add`,
        { websiteLink, displayName, frequencyInMins },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      return response.data; // axios returns the data directly
    } catch (error) {
      setWindow(3);
      console.error("Error in API call", error);
      return null; // or handle error based on your logic
    }
  };
  

  const HandleCloseButtonClick = () => {
    setOpenWebsiteTrackModal(false)
    setWindow(0)
  }

  const handleSubmitViewwebpagesummary = () => {
    setOpenWebsiteTrackModal(false)
    setWindow(0);
  }

  const handleCheckForUpdateSelect = (event) => {
    setCheckforupdates(event.target.value);
  };

  const handleRetryAddWebsite = () => {
    setWindow(0);
  }

  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.80) !important",
    },
  };

  return (
    <Modal open={openWebsiteTrackModal}
      // onClose={handleClose}
      aria-labelledby="audio-settings-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: window === 2 || window === 3 ? '480px' : '720px',
          // height: '519px',
          backgroundColor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: theme === 'dark' ? settingsModal_bg_color_dm : '',
          borderRadius: '8px',
        }}
      >
        <Typography
          id="audio-settings-title"
          variant="h6"
          component="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // mb: 2,
            borderBottom: `1px solid ${theme === 'dark' ? '#535774' : ''}`,
            padding: '20px',
          }}
          style={{ fontSize: '20px', fontWeight: '600', color: theme === 'dark' ? '#FFFFFF' : '' }}
        >
          Add a website for tracking
          <IconButton
            aria-label="close"
            onClick={HandleCloseButtonClick}
            sx={{
              // color: (theme) => theme.palette.grey[500],
              color : '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", padding: "40px 40px 40px 40px" }}>
          {
            window === 0 &&
            <Box>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                    marginBottom: "24px",
                  }}>
                  Stay up to date with ModalX
                </Typography>
              </Box>

              <Box
                sx={{
                  // top: "36px",
                  opacity: "0px",
                }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}>
                  You can track any website with ModalX. Just enter the URL, and ModalX will notify you whenever there is a change on the webpage, along with a summary of the update!
                </Typography>
              </Box>

              <Box sx={{ marginBottom: "40px", }}>
              <TextField
                type="weblink"
                onChange={(e) => setWebSiteLink(e.target.value)}
                value={websitelink}
                id="outlined-basic"
                label="Website Link"
                variant="outlined"
                sx={customTextFieldStyles}
                InputLabelProps={{
                  style: { color: "rgba(255, 255, 255, 0.6)" },
                }}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  height: "56px",
                  color: "#FFFFFF",
                  backgroundColor: "#232746",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                  marginTop: "40px",
                }}
              />

                <FormHelperText
                  sx={{
                    color: "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                    margin: "4px 0px 0px 16px",
                    alignSelf: "start",
                  }}
                >
                  {`eg. https://home.treasury.gov/policy-issues/tax-policy`}
                </FormHelperText>
              </Box>

              <Box sx={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "140%",
                textAlign: "left",
                gap: "8px",
                color: "rgba(255, 255, 255, 0.80)",
              }}>
                <Box>
                  <img src={Info} alt="info" />
                </Box>

                <Box sx={{

                }}>
                  <Typography sx={{
                    fontFamily : 'Inter',
                    fontSize : '12px',
                    fontWeight : 400,
                    lineHeight : '16.8px',
                    letterSpacing : '0.10000000149011612px',
                    textAlign : 'left', 
                    color : '#FFFFFF',
                    opacity : '0.80',
                  }}>
                  {`ModalX will track changes in text content on individual webpages such as example.com/policy, or example.com (home page). It will not track changes across the entire website ‘example.com’ or changes in styles or layouts.`}
                  </Typography>

                </Box>
              </Box>
            </Box>
          }
          {
            window === 1 &&
            <Box>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                    marginBottom: "24px",
                  }}>
                  Stay up to date with ModalX
                </Typography>
              </Box>

              <Box
                sx={{
                  opacity: "0px",
                }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}>
                  You can track any website with ModalX. Just enter the URL, and ModalX will notify you whenever there is a change on the webpage, along with a summary of the update!
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "40px", }}>
                <TextField
                  type="weblink"
                  onChange={(e) => setWebSiteLink(e.target.value)}
                  value={websitelink}
                  id="outlined-basic"
                  label="Website Link"
                  variant="outlined"
                  sx={customTextFieldStyles}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "56px",
                    color: "#FFFFFF",
                    backgroundColor: "#232746",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                    placeholderfontsize: "16px",
                    marginTop: "40px"
                  }}
                />
              </Box>

              <Box sx={{ minWidth: "100%", marginBottom: "40px" }}><FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"   sx={{
                        color: "rgba(255, 255, 255, 0.80)",
                        "&.Mui-focused": {
                          color: "rgba(255, 255, 255, 0.80) !important",
                        }
                      }} >Check for updates:</InputLabel>
                <Select sx={{
                  ...customTextFieldStyles, 
                  "& .MuiSelect-select": {
                    backgroundColor: '#282D51',
                  },
                  '.MuiSelect-icon': {
                      color: '#ffffff', 
                    },
                  // "& .MuiOutlinedInput-root": {
                  //   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  //     borderColor: "rgba(255, 255, 255, 0.80)", // Ensure border remains the same
                  //   },
                  // },
                }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={checkforupdates}
                  label="Check for updates:"
                  onChange={handleCheckForUpdateSelect}
                >
                  <MenuItem sx={{
                    bgcolor: '#2F355F', marginTop: '-8px', marginBottom: '-8px', color: "white", height: '56px',
                    '&:hover': {
                      bgcolor: '#393F69'
                    },
                    '&.Mui-selected': {
                      bgcolor: '#282D51 !important', // Ensure selected background color stays
                    },
                  }} value={15}>Every 15 Minutes</MenuItem>
                  <MenuItem sx={{
                    bgcolor: '#2F355F', marginTop: '-8px', marginBottom: '-8px', color: "white", height: '56px',
                    '&:hover': {
                      bgcolor: '#393F69'
                    },
                    '&.Mui-selected': {
                      bgcolor: '#282D51 !important', // Ensure selected background color stays
                    },
                  }} value={10080}>Every 7 days</MenuItem>
                  <MenuItem sx={{
                    bgcolor: '#2F355F', marginTop: '-8px', marginBottom: '-8px', color: "white", height: '56px',
                    '&:hover': {
                      bgcolor: '#393F69'
                    },
                    '&.Mui-selected': {
                      bgcolor: '#393F69 !important', // Ensure selected background color stays
                    },
                  }} value={20160}>Every 14 days</MenuItem>
                  <MenuItem sx={{
                    bgcolor: '#2F355F', marginTop: '-8px', marginBottom: '-8px', color: "white", height: '56px',
                    '&:hover': {
                      bgcolor: '#393F69'
                    },
                    '&.Mui-selected': {
                      bgcolor: '#393F69 !important', // Ensure selected background color stays
                    },
                  }} value={43200}>Every 30 days</MenuItem>
                </Select></FormControl></Box>

              <Box sx={{ marginBottom: "40px", }}>
                <TextField
                  type="weblink"
                  onChange={(e) => setSaveAs(e.target.value)}
                  value={saveas}
                  id="outlined-basic"
                  label="Save As"
                  variant="outlined"
                  sx={customTextFieldStyles}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "56px",
                    color: "#FFFFFF",
                    backgroundColor: "#232746",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                    placeholderfontsize: "16px",
                  }}
                /></Box>


              {/* <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "40px", padding: "0px", gap: "12px" }}>
                <Checkbox {...label} sx={{
                  padding: "0px", margin: "0px", color: "#CAC4D0",
                  '&.Mui-checked': { color: "#CAC4D0", },
                }} checked={true}/>
                <Box sx={{ padding: "0px" }}>{`Receive email notifications about webpage updates`}</Box>
              </Box>
            </Box> */}
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "40px", padding: "0px", gap: "12px" }}>
                <Checkbox {...label} sx={{
                  padding: "0px", margin: "0px", color: "#CAC4D0",
                  '&.Mui-checked': { color: "#CAC4D0", },
                }} defaultChecked />
                <Box sx={{ padding: "0px" }}>{`Receive email notifications about webpage updates`}</Box>
              </Box>
            </Box>
          }

          {
            window === 2 && (
              <Box
                onClick={(event) => event.stopPropagation()}
                sx={{}}>
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <Box
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={successIcon} alt="success" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "left"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        alignSelf: "stretch",
                        textAlign: "center",
                        margin: "0px 0px",
                      }}
                    >
                      Website added
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "left"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      ModalX will track changes on this webpage and notify you over email whenever there is an update
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "40px",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: New_Project_Button_bg_color,
                        color: "rgba(23, 24, 44, 1)",
                        padding: "10px 24px 10px 24px",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        width: "100%",
                        textTransform: "none",
                        "&:hover": {
                          bgcolor: "#ffd9aa",
                        },
                      }}
                    onClick={handleSubmitViewwebpagesummary}
                    >
                      Done
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }

          {
            window === 3 && (
              <Box
                onClick={(event) => event.stopPropagation()}
                sx={{}}>
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <Box
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ErrorIcon sx={{color:'red'}}/>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        alignSelf: "stretch",
                        textAlign: "center",
                        margin: "0px 0px",
                      }}
                    >
                      Website could not be added
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "left"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      An error occurred while adding the website for tracking. Please try adding it again.
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "40px",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: New_Project_Button_bg_color,
                        color: "rgba(23, 24, 44, 1)",
                        padding: "10px 24px 10px 24px",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        width: "100%",
                        textTransform: "none",
                        "&:hover": {
                          bgcolor: "#ffd9aa",
                        },
                      }}
                    onClick={handleRetryAddWebsite}
                    >
                      Retry adding website
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }

        </Box>

        {
          (window == 1 || window == 0) &&
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.12)",
              p: 2,
            }}
          >
            <Button
              onClick={HandleCloseButtonClick}
              sx={{
                mr: 1,
                backgroundColor: theme === 'dark' ? uploadFiles_button_default_bg_color : '',
                '&:hover': {
                  backgroundColor: uploadFiles_button_hovered_bg_color,
                },
                color: '#17182C',
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'none',
                fontFamily: 'Inter, sans-serif',
              }}
            >
              Close
            </Button>
            {
              window === 0 ? <Button
                variant="contained"
                onClick={handleNextButtonClick}
                sx={{
                  backgroundColor: theme === 'dark' ? button_bg_color_for_New_Project : '',
                  '&:hover': {
                    backgroundColor: '#FFD9AA',
                  },
                  color: '#17182C',
                  fontSize: '16px',
                  fontWeight: '600',
                  textTransform: 'none',
                  fontFamily: 'Inter, sans-serif',
                }}
              >Next
              </Button> :
                <Button
                  variant="contained"
                  onClick={() => handleAddWebsiteButtonClick(websitelink,saveas,checkforupdates)}
                  sx={{
                    backgroundColor: theme === 'dark' ? button_bg_color_for_New_Project : '',
                    '&:hover': {
                      backgroundColor: '#FFD9AA',
                    },
                    color: '#17182C',
                    fontSize: '16px',
                    fontWeight: 600,
                    textTransform: 'none',
                    fontFamily: 'Inter, sans-serif',
                  }}
                >Add website</Button>
            }

          </Box>
        }
      </Box>
    </Modal>
  )
}

export default TrackWebsiteModal