import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { ButtonClick, CreateNewProjectConfirmation, ErrorEvent, trackButtonEvent } from "../../../config/analytics";
import sourceUrl from "../../../api/Source.api";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

function WarRoomHeaderForMobileResponsive() {
  const mobileView = useMediaQuery("(max-width:992px)");
  const {
    showLeftsideBar,
    setShowLeftsideBar,
    setShowSidebar,
    showSidebar,
    setShowRightsideBar,
    setShowSettingBar,
    showSettingBar,
    clearThread,
    setThreadFiles,
    setHistoryFiles,
    setThreadId,
    currentThread,
    showSuggested ,
    setShowSuggested,
    threadFiles,
    historyFiles,
    waitThread,
    fileProcessing,
    isLoggedIn,
  } = useWorkspace();
  const token = localStorage.getItem("token");
  const files = [...(threadFiles ? [...threadFiles].reverse() : []), ...(historyFiles || [])];
  const [filesCount,setFilesCount] = useState(0);
  const handleClickLeftSideBar = () => {
    setShowSidebar(!showSidebar);
    setShowLeftsideBar(!showSidebar);
  };
  const handleClickLeftSettingBar = () => {
    setShowSettingBar(!showSettingBar);
    setShowRightsideBar(!showSettingBar);
  };

  useEffect(() => {
    setFilesCount(files.length);
}, [files]);
  
  // console.log("88888888888888888888888888888888888888888888888",filesCount)

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem("threadId", newThreadId); // Store the new threadId in localStorage
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      ErrorEvent(error, error.message)
      console.error("Thread creation error:", error);
      return null;
    }
  };

  const handleNewChat = async () => {
    ButtonClick("new_project_click", "new Project")
    setShowSuggested(true)
    clearThread();
    setThreadFiles([]);
    setHistoryFiles([]);
    localStorage.removeItem("current_thread_id");
    localStorage.removeItem("current_history_id");
    const newThreadId = await createThreadId();
    console.log(newThreadId);
    CreateNewProjectConfirmation(newThreadId.data)
    setThreadId(newThreadId);
    trackButtonEvent("button_click_New_Project", {
      button_name: "New Project",
      button_position: "Top-left",
    });
  };

  return (
    <>
      <Box
        sx={{
          display: mobileView ? "block" : "none",
          width: "100%",
          marginTop: "-15px",
        }}
      >
        <Box
          sx={{
            height: "40px",
            margin: "13.5px 20px 13.5px 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            onClick={handleClickLeftSideBar}
            sx={{
              width: "24px",
              height: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MenuIcon />
          </Box>
          <Box
            sx={{
              width: currentThread.length > 0 ? "96px" : '40px',
              height: "40px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <Box sx={{
                display : currentThread.length > 0 ?'block' : 'none'
            }}>
              <Box
                onClick={(waitThread || fileProcessing ) ? undefined : handleNewChat}
                sx={{
                  width: "40px",
                  height: "40px",
                  // bgcolor: "#1F233E",
                  bgcolor: waitThread || fileProcessing?'rgba(230, 224, 233, 0.12)':'#1F233E',           
                  
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": {
                    bgcolor: !waitThread && !fileProcessing ? '#282D51' : undefined,
                  },
                }}
              >
                <AddIcon sx={{color: waitThread || fileProcessing?'#E6E0E9':'',}} />
              </Box>
            </Box>
            {/* background: ; */}

            {/* <Box
              onClick={handleClickLeftSettingBar}
              sx={{
                width: "40px",
                height: "40px",
                bgcolor: "#1F233E",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FolderOutlinedIcon />
            </Box> */}
            <Box
              onClick={handleClickLeftSettingBar}
              sx={{
                position: "relative", // Keep this to allow the badge to position relative to the icon
                width: "40px",
                height: "40px",
                bgcolor: "#1F233E",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",  // Centers the FolderOutlinedIcon horizontally
                alignItems: "center",      // Centers the FolderOutlinedIcon vertically
                "&:hover": {
                  bgcolor: '#282D51',
                },
              }}
            >
              {/* Folder Icon */}
              <FolderOutlinedIcon
                sx={{
                  position: "relative", // Keep it normal to maintain centering within the parent Box
                }}
              />
              
              {/* Badge for the file count */}
              {filesCount > 0 && <Box
                sx={{
                  position: "absolute",  // Positions the badge on the top-right
                  top: "-5px",
                  right: "-5px",
                  width: "18px",
                  height: "18px",
                  bgcolor: "#CAC4D0",  // Red background for the count badge
                  color: "#17182C",       // White text
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  padding: "2px",
                  fontWeight:"500"
                }}
              >
                {filesCount}
              </Box>}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default WarRoomHeaderForMobileResponsive;
