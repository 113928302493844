import React,{useEffect, useState} from 'react'
import WebsiteListingPage from './WebsiteListingPage'
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import MessageWindowForTrackWebsite from './MessageWindowForTrackWebsite';
import axios from 'axios';
import sourceUrl from '../../../../api/Source.api';
import TrackWebsiteModal from './TrackWebsiteModal';

const WebTrackHome = () => {
    const {iswebTrackClicked,currentWebsiteLinkedId} = useWorkspace();
    const token = localStorage.getItem('token');
    const [currentWebs,setCurrentWebs] = useState([]);
    const [addWebClicked, setAddWebClicked] = useState(false);
    const [refetchClicked,setRefetchClicked] = useState(false);
    const [updateWebList,setUpdateWebList] = useState(false);

    const handleUpdateList = () => {
      setUpdateWebList(!updateWebList);
    };

    useEffect(() => {
      fetchWebsites();
    }, [addWebClicked,updateWebList]);

    const handleAddWebClicked = () => {
      setAddWebClicked(!addWebClicked);
    };

    const handleRefetchClicked = () => {
      setRefetchClicked(!refetchClicked);
    };
  
    const fetchWebsites = async () => {
      try {
        const res = await axios.get(`${sourceUrl}/websiteTracking/listAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const sortedData = res.data.data.sort((a, b) => {
          if (a.notifications === b.notifications) return 0;
          return a.notifications ? -1 : 1;
        });
        setCurrentWebs(sortedData);
      } catch (error) {
        console.error(error);
      }
    };

  return (
    <div>
        {iswebTrackClicked && currentWebsiteLinkedId ? <MessageWindowForTrackWebsite website={currentWebs} onHandleRefetch={handleRefetchClicked} refetchClicked={refetchClicked} updateWebList={handleUpdateList} /> : <WebsiteListingPage website={currentWebs} />}
        <TrackWebsiteModal
          onAddWebClicked={handleAddWebClicked}
        />
    </div>
  )
}

export default WebTrackHome