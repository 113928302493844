import React,{useRef,useEffect,useState} from 'react';
import NavbarForTrackWebsite from './NavbarForTrackWebsite';
import MessageForTrackWebside from './MessageForTrackWebsite';
import { Box, Typography,CardMedia } from "@mui/material";
import info_icon from '../../../../assets/info_icon.png';
import { useWorkspace } from '../../../../contexts/WorkspaceContext';
import axios from 'axios';
import sourceUrl from '../../../../api/Source.api';
import MessageForTrackWebsiteEmpty from './MessageForTrackWebsiteEmpty';


function MessageWindowForTrackWebsite({ website,onHandleRefetch,refetchClicked,updateWebList}) {
  const {currentWebsiteLinkedId} = useWorkspace();
  const scrollRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");


  useEffect(() => {
    getSingleWebTrackDetails(currentWebsiteLinkedId);
  }, [currentWebsiteLinkedId, refetchClicked]);


    const getSingleWebTrackDetails = async (id) => {
    try {
      const res = await axios.get(`${sourceUrl}/websiteTracking/get/${id}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      setData(res.data.data.notifications);
      
      if(res.data.status){
        invokeViewedApi(currentWebsiteLinkedId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invokeViewedApi = async(id) => {
    try {
      const res = await axios.get(`${sourceUrl}/websiteTracking/viewed/${id}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    // Automatically scroll to bottom smoothly after data is set
    if (scrollRef.current && shouldScroll) {
      scrollToBottomSmoothly();
    }
  }, [data]);

  const scrollToBottomSmoothly = () => {
    if (!scrollRef.current) return; // Ensure scrollRef.current is not null
    const duration = 1000; // Duration in milliseconds
    const start = scrollRef.current.scrollTop;
    const end = scrollRef.current.scrollHeight;
    const change = end - start;
    const increment = 5; // Increment in milliseconds

    let currentTime = 0;

    const animateScroll = () => {
      if (!scrollRef.current) return; // Check if the ref is still attached
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      scrollRef.current.scrollTop = val;

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    animateScroll();
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollTop = scrollRef.current.scrollTop;
      const maxScrollTop = scrollRef.current.scrollHeight - scrollRef.current.clientHeight;

      // Determine if user is scrolling
      setIsUserScrolling(scrollTop < maxScrollTop);

      // Update shouldScroll based on user's position
      if (scrollTop >= maxScrollTop) {
        setShouldScroll(true);
      } else {
        setShouldScroll(false);
      }
    }
  };



  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <NavbarForTrackWebsite website={website} onHandleRefetch={onHandleRefetch} updateWebList={updateWebList}/>
  
      <div
      ref={scrollRef}
        style={{
          flex: 1, // This makes the inner div take the available space
          marginBottom: '20px', // Adjust the margin as needed
          overflowY: 'scroll'
        }}
        onScroll={handleScroll}
      >
        {
          data?.length > 0 ?   <MessageForTrackWebside refetchClicked={refetchClicked}  currentWebData={data}/> : <MessageForTrackWebsiteEmpty /> 
        }
      
      </div>
      <Box sx={{
        width : '100%',
        display : 'flex',
        justifyContent : 'center',
        marginBottom : '40px',

      }}>
        <Box sx={{
          width : '720px',
          display : 'flex',
          justifyContent : 'center',
          alignItems : 'start',

        }}>
          <Box sx={{
            width : '20px',
            // height : '14px', 
            marginRight : '8px',


          }}>
              <CardMedia
                component="img"
                image={info_icon}
                alt="loading"
              />

          </Box>
          <Typography sx={{
          // maxWidth : '720px',
          color : '#FFFFFF',
          textAlign : 'left',
          fontFamily : 'Inter',
          fontSize : '12px',
          fontWeight : '400',
          opacity : '0.8',


        }}>ModalX will track changes in text content on individual webpages such as example.com/policy, or example.com (home page). It will not track changes across the entire website ‘example.com’ or changes in styles or layouts.</Typography>

        </Box>
         
      </Box>
    </div>
  );
  
}

export default MessageWindowForTrackWebsite;
