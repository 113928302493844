import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from "../../../../api/Source.api";
import { hoveredSettings_Nav_bg_dm } from "../../../../constants/colors";

const VoiceSettings = () => {
  // const [audioSettingVoicesloading, setAudioSettingVoicesLoading] = useState(true);
  const { audioVoice, setAudioVoice, audioSettingVoices, setAudioSettingVoices, setAudioSettingVoicesLoading, audioSettingVoicesloading  } = useWorkspace();
  const [audioVoices, setAudioVoices] = useState([]);
  const [playingVoice, setPlayingVoice] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const theme = localStorage.getItem('theme');
  const token = localStorage.getItem('token');

  const containerRef = useRef(null);

  const fetchVoices = async () => {
    try {
      const response = await fetch(`${sourceUrl}/audio/list`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
      const data = await response.json();
      console.log('Fetched voices:', data.data.data); // Log the response for debugging
      setAudioSettingVoices(data.data.data);
      if (data.data.data.length > 0 && !audioVoice) {
        // Set default selected voice if none is selected yet
        setAudioVoice(data.data.data[0].name);
      }
    } catch (error) {
      console.error('Error fetching voices:', error);
    }
    setAudioSettingVoicesLoading(false)
  };

  // console.log(audioSettingVoices.length, "========================voices.length=========================")
  useEffect(() => {
    if(!audioSettingVoicesloading  && (audioSettingVoices.length === 0 || audioSettingVoices.length === undefined  ||audioVoice === null) ){
      setAudioSettingVoicesLoading(true)
      fetchVoices();
    }
    // Cleanup audio element when the component unmounts
    return () => {
      if (audioElement) {
        audioElement.pause();
        // setAudioElement(null);
      }
    };
  }, []);

  const handleVoiceSelect = (selectedVoice) => {
    setAudioVoice(selectedVoice.name);
    if (playingVoice === selectedVoice.voice_id) {
      audioElement.pause();
      setPlayingVoice(null);
    } else {
      if (audioElement) {
        audioElement.pause();
      }
      const newAudioElement = new Audio(selectedVoice.previewURL);
      newAudioElement.play();
      setPlayingVoice(selectedVoice.voice_id);
      setAudioElement(newAudioElement);
    }
  };

  useEffect(() => {
    setAudioVoices(audioSettingVoices);
  }, [audioSettingVoices])
  

  const handleVoiceClick = (selectedVoice) => {
    setAudioVoice(selectedVoice.voice_id)
    const updatedVoiceArray = [
      selectedVoice,
      ...audioSettingVoices.filter((voice) => voice.voice_id !== selectedVoice.voice_id)
    ];
  
    // Update the state with the new order
    setAudioSettingVoices(updatedVoiceArray);
    containerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  console.log(audioSettingVoices)

  return (
   <> {
    audioSettingVoicesloading ? 
    <Box sx={{ p: 2 }}>
          <Typography>Loading Voices</Typography>
    </Box>
    :
    <Box sx={{ p: 2 }} ref={containerRef}>
      <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: '500', width: '143px', height: '20px', marginBottom: '20px' }}>
        Select Voice
      </Typography>
      {/* <Grid container spacing={1} justifyContent="start" >
        {Array.isArray(audioSettingVoices) && audioSettingVoices.map((voiceOption) => (
          <Grid item key={voiceOption.voice_id} xs={12}>
            <Grid container alignItems="center" gap={'20px'}>
              <Grid item>
                <IconButton
                  onClick={() => handleVoiceSelect(voiceOption)}
                  sx={{
                    color: playingVoice === voiceOption.voice_id ? '#ffffff' : '#9e9e9e', // Custom icon color
                    backgroundColor: playingVoice === voiceOption.voice_id ? '#3a3f69' : '#30355f',
                    '&:hover': {
                      backgroundColor: '#3a3f69', // Background color on hover
                    },
                  }}
                >
                  {playingVoice === voiceOption.voice_id ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
              </Grid>
              <Grid item  >
                <Button
                  variant="outlined"
                  onClick={() => setAudioVoice(voiceOption.name)}
                  sx={{
                    width: 'auto',
                    backgroundColor: audioVoice === voiceOption.name ? '#3a3f69' : '#30355f',
                    color: theme === 'dark' ? 'white' : 'black',
                    '&:hover': {
                      backgroundColor: audioVoice === voiceOption.name ? '#3a3f69' : '',
                    },
                    borderColor: audioVoice === voiceOption.name ? '#cac4d0' : 'transparent',
                    mx: 0.5, // Add horizontal margin for spacing
                    textTransform:'none'
                  }}
                >
                  {voiceOption.name}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid> */}

        <Grid container spacing={1} justifyContent="flex-start">
          {Array.isArray(audioVoices) &&
            audioVoices?.map((voiceOption,index) => (
              <Grid item key={voiceOption.voice_id+index} xs={12} sm={6}>
                <Grid container alignItems="center" gap="5px">
                  <Grid item sx={{ flex: '1 1 auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'start', gap: '5px' }}>
                      {/* Updated IconButton Styling */}


                      {/* Box acting as button with Typography */}
                      <Box
                        component="button"
                        onClick={() => handleVoiceClick(voiceOption)}
                        sx={{
                          width: '100%',
                          backgroundColor: audioVoice === voiceOption.voice_id ? '#3a3f69' : '#30355f',
                          color: theme === 'dark' ? 'white' : 'black',
                          '&:hover': {
                            backgroundColor: audioVoice === voiceOption.voice_id ? '#3a3f69' : '',
                          },
                          border: `1px solid ${audioVoice === voiceOption.voice_id ? '#cac4d0' : 'transparent'}`,
                          mx: 0.5,
                          textTransform: 'none',
                          padding: '8px 12px',
                          cursor: 'pointer',
                          borderRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start',
                        }}
                      >
                        <Box sx={{ textAlign: 'left', width : '100%',  }}>
                          <Typography sx={{fontSize : '14px',}}>Name: {voiceOption.name}</Typography>
                          <Typography sx={{fontSize : '14px'}}>Age: {voiceOption.labels.age}</Typography>
                          <Typography sx={{fontSize : '14px'}}>Gender: {voiceOption.labels.gender}</Typography>
                          <Typography sx={{fontSize : '14px'}}>Accent: {voiceOption.labels.accent}</Typography>
                          <Typography sx={{fontSize : '14px'}}>Description: {voiceOption.labels.description}</Typography>
                          <Box sx={{
                            marginTop : '10px', 
                            width : '100%',
                            // bgcolor : 'red',
                            display   : "flex",
                            // justifyContent : "space-between",
                            alignItems : "center",
                            // padding : '0px 20px 0px 20px',
                          }}>
                            <IconButton
                              onClick={() => handleVoiceSelect(voiceOption)}
                              sx={{
                                
                                color: playingVoice === voiceOption.voice_id ? '#9e9e9e' : '#ffffff',
                                backgroundColor: playingVoice === voiceOption.voice_id ? '#53567A' : '#3B3E5B',
                                transition: 'background-color 0.3s ease, transform 0.2s ease',
                                '&:hover': {
                                  backgroundColor: '#282D51',
                                  // transform: 'scale(1.1)', // Subtle zoom on hover
                                },
                                width : '30px',
                                height : '30px',
                                padding: '12px',
                                borderRadius: '50%', 
                              }}
                            >
                              {playingVoice === voiceOption.voice_id ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                            <Typography sx={{fontSize : '14px', marginLeft : '12px'}}>Try this voice</Typography>

                          </Box>

                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>


    </Box>
 

    }</>
  )
    
};

export default VoiceSettings;


// import React, { useState, useEffect } from "react";
// import { Box, Typography, Grid, IconButton } from "@mui/material";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import PauseIcon from "@mui/icons-material/Pause";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../../api/Source.api";

// const VoiceSettings = () => {
//   const { audioVoice, setAudioVoice, audioSettingVoices, setAudioSettingVoices, setAudioSettingVoicesLoading, audioSettingVoicesloading } = useWorkspace();
//   const [playingVoice, setPlayingVoice] = useState(null);
//   const [audioElement, setAudioElement] = useState(null);
//   const theme = localStorage.getItem('theme');
//   const token = localStorage.getItem('token');

//   const fetchVoices = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/audio/list`, {
//         method: 'GET',
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/x-www-form-urlencoded',
//         }
//       });
//       const data = await response.json();
//       setAudioSettingVoices(data.data.data);
//       if (data.data.data.length > 0 && !audioVoice) {
//         setAudioVoice(data.data.data[0].name);
//       }
//     } catch (error) {
//       console.error('Error fetching voices:', error);
//     }
//     setAudioSettingVoicesLoading(false);
//   };

//   useEffect(() => {
//     if (!audioSettingVoicesloading && (audioSettingVoices.length === 0 || !audioVoice)) {
//       setAudioSettingVoicesLoading(true);
//       fetchVoices();
//     }
//     return () => {
//       if (audioElement) {
//         audioElement.pause();
//       }
//     };
//   }, [audioSettingVoicesloading, audioSettingVoices, audioVoice]);

//   const handleVoiceSelect = (selectedVoice) => {
//     setAudioVoice(selectedVoice.name);
//     if (playingVoice === selectedVoice.voice_id) {
//       audioElement.pause();
//       setPlayingVoice(null);
//     } else {
//       if (audioElement) audioElement.pause();
//       const newAudioElement = new Audio(selectedVoice.previewURL);
//       newAudioElement.play();
//       setPlayingVoice(selectedVoice.voice_id);
//       setAudioElement(newAudioElement);
//     }
//   };

//   return (
//     <>
//       {audioSettingVoicesloading ? (
//         <Box sx={{ p: 2 }}>
//           <Typography>Loading Voices...</Typography>
//         </Box>
//       ) : (
//         <Box sx={{ p: 2 }}>
//           <Typography variant="h6" gutterBottom sx={{ fontSize: '16px', fontWeight: '500', mb: 2 }}>
//             Select Voice
//           </Typography>
//           <Grid container spacing={1} justifyContent="flex-start">
//             {audioSettingVoices.map((voiceOption) => (
//               <Grid item key={voiceOption.voice_id} xs={12} sm={6}>
//                 <Box
//                   component="button"
//                   onClick={() => setAudioVoice(voiceOption.voice_id)}
//                   sx={{
//                     width: '100%',
//                     backgroundColor: audioVoice === voiceOption.voice_id ? '#3a3f69' : '#30355f',
//                     color: theme === 'dark' ? 'white' : 'black',
//                     '&:hover': {
//                       backgroundColor: audioVoice === voiceOption.voice_id ? '#3a3f69' : '',
//                     },
//                     border: `1px solid ${audioVoice === voiceOption.voice_id ? '#cac4d0' : 'transparent'}`,
//                     p: 2,
//                     cursor: 'pointer',
//                     borderRadius: '4px',
//                     textAlign: 'left',
//                   }}
//                 >
//                   <Typography sx={{ fontSize: '14px' }}>Name: {voiceOption.name}</Typography>
//                   <Typography sx={{ fontSize: '14px' }}>Age: {voiceOption.labels.age}</Typography>
//                   <Typography sx={{ fontSize: '14px' }}>Gender: {voiceOption.labels.gender}</Typography>
//                   <Typography sx={{ fontSize: '14px' }}>Accent: {voiceOption.labels.accent}</Typography>
//                   <Typography sx={{ fontSize: '14px' }}>Description: {voiceOption.labels.description}</Typography>
//                   <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
//                     <IconButton
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleVoiceSelect(voiceOption);
//                       }}
//                       sx={{
//                         color: playingVoice === voiceOption.voice_id ? '#9e9e9e' : '#ffffff',
//                         backgroundColor: playingVoice === voiceOption.voice_id ? '#53567A' : '#3B3E5B',
//                         transition: 'background-color 0.3s ease',
//                         '&:hover': { backgroundColor: '#282D51' },
//                         width: '30px',
//                         height: '30px',
//                         p: '12px',
//                         borderRadius: '50%',
//                       }}
//                     >
//                       {playingVoice === voiceOption.voice_id ? <PauseIcon /> : <PlayArrowIcon />}
//                     </IconButton>
//                     <Typography sx={{ fontSize: '14px', ml: 1 }}>Try this voice</Typography>
//                   </Box>
//                 </Box>
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       )}
//     </>
//   );
// };

// export default VoiceSettings;

