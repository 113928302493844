// Function to create a new thread by optaining a threadId

import axios from "axios";
import sourceUrl from "../api/Source.api";
import mixpanel from "mixpanel-browser";
import { ErrorEvent, PromptInputEvent, } from "../config/analytics";

export async function createThread(setThreadId) {

  const token = localStorage.getItem('token');
  const existingThreadId = localStorage.getItem('current_thread_id');

  // If a threadId already exists, use it
  if (existingThreadId) {
    setThreadId(existingThreadId);
    return existingThreadId;
  }

  try {
    const response = await fetch(`${sourceUrl}/threads`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });

    const responseData = await response.json();

    if (responseData.status) {
      const newThreadId = responseData.data.match(/(.*)/)[1];
      setThreadId(newThreadId);
      localStorage.setItem('threadId', newThreadId); // Store the new threadId in localStorage
      return newThreadId;
    } else {
      throw new Error("Thread creation failed");
    }
  } catch (error) {
    console.error("Thread creation error:", error);
    return null;
  }
}


// export async function createThread(setThreadId) {
//   alert("nsdlnflsdf")
//   const token = localStorage.getItem('token') ;
//   try {
//     const response = await fetch("https://www.modalx.ai/threads", {
//       method: "POST",
//       credentials: "include",
//       headers: { 
//         "Content-Type": "application/json",
//         "Authorization":`Bearer ${token}`
//       },
//     });

//     const responseData = await response.json();
    
//     if (responseData.status) {
//       // setThreadId(responseData.data.match(/thread-(.*)/)[1]);
//       // return responseData.data.match(/thread-(.*)/)[1];
//       setThreadId(responseData.data.match(/(.*)/)[1]);
//       return responseData.data.match(/(.*)/)[1];
//     } else {
//       throw new Error("Thread creation failed");
//     }
//   } catch (error) {
//     console.error("Thread creation error:", error);
//     return null;
//   }
// }

// Function to find the URL in the modalX response (temporary, #todo by backend in production)
const findUrl = (msg) => {
  const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
  const match = urlRegex.exec(msg);
  return match ? match[1] : null;
};

const findUrls = (msg) => {
  const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt|pdf))/g;
  const matches = msg.match(urlRegex); // Use match to find all occurrences
  // console.log(matches, "=============================matches===================");
  return matches ? matches : null; // Return all matches or null if no matches
}

export async function sendMessage(
  latestEntry,
  threadId,
  threadFiles,
  threadSettings,
  setThreadId,
  templateId,
  historyFiles,
  imageFormat,
  imageModel,
  imageRatio,
  videoAvatar,
  videoBackground,
  videoVoice,
  videoAvatarLanguage,
  audioLanguage,
  audioVoice,
  isValidDetails,
  selectedGenVideoVoice,
  selectedGenVideoLanguage,
  minDimensionPixels,
  selectedHeightForVideoGen,
  selectedWidthForVideoGen,
  selectCaptionFontSizeForVideoGen,
  selectCaptionFontWeightForVideoGen,
  isBold,
  showCaption,
  captionBackgoundColor,
  color,
  alignment,
  valignment,
  selectedCaptionFontForVideoGen,
  fontSize
) {
  const targetThreadId = threadId || (await createThread(setThreadId));
  const req_target_threadId = targetThreadId.match(/thread-(.*)/)[1] ;
  const token = localStorage.getItem('token') ;
  const email = localStorage.getItem("userEmail");
  console.log("inside send message",threadId)
  if (!targetThreadId) {
    console.error("No threadId available for sending message.");
    return;
  }

  // const synthesiaVideoSettings =
  //   threadSettings.synthesiaBackground ||
  //   threadSettings.synthesiaAvatar ||
  //   threadSettings.synthesiaVoice
  //     ? {
  //         avatarId: threadSettings.synthesiaAvatar.avatarId
  //           ? threadSettings.synthesiaAvatar.avatarId
  //           : null,
  //         avatarVoiceId: threadSettings.synthesiaVoice.VoiceId
  //           ? threadSettings.synthesiaVoice.VoiceId
  //           : null,
  //         backgroundImageId: threadSettings.synthesiaBackground.imageID
  //           ? threadSettings.synthesiaBackground.imageID
  //           : null,
  //       }
  //     : null;
  // const videoSettings = videoAvatar || videoBackground || videoVoice || videoAvatarLanguage ? {
  //   avatarId: videoAvatar ? String(videoAvatar) : null,
  //   backgroundImageUrl: videoBackground? String(videoBackground) : null,
  //   avatarVoiceId: videoVoice? String(videoVoice): null,
  //   avatarLanguage: videoAvatarLanguage? String(videoAvatarLanguage) : '',
  // } : null;

  // console.log(selectedGenVideoVoice, '=============selectedGenVideoVoice==========')
  // console.log(selectedGenVideoLanguage, '=============selectedGenVideoLanguage==========')

  const videoSettings = {
    script: "Welcome to the tutorial on video generation!",
    voice: selectedGenVideoVoice ? selectedGenVideoVoice.name : null,
    voiceVolume: 0.9,
    captionFontName: selectedCaptionFontForVideoGen || null,
    captionFontSize: fontSize ? Number(fontSize) : null,
    // captionFontSize: selectCaptionFontSizeForVideoGen ? selectCaptionFontSizeForVideoGen.value : null,
    captionFontWeight: isBold ? 700 : 400,
    captionTextColor: {
      red: color ? color.r : 255,
      green: color ? color.g : 255,
      blue: color ? color.b : 255
    },
    captionTextJustification: alignment.toUpperCase() || null,
    captionVerticalAlignment: valignment || null,
    captionStrokeColor: {
      red: 0,
      green: 0,
      blue: 0
    },
    captionStrokeWeight: null,
    captionBackgroundStyleType: null,
    captionBackgroundColor: {
      red: captionBackgoundColor ? captionBackgoundColor.r : null,
      green: captionBackgoundColor ? captionBackgoundColor.g : null,
      blue: captionBackgoundColor ? captionBackgoundColor.b : null,
    },
    captionBackgroundBorderRadius: null,
    captionBackgroundOpacity: null,
    captionIsHidden: showCaption ? false : true,
    aspectRatio: selectedWidthForVideoGen &&  selectedHeightForVideoGen  ? {width:selectedWidthForVideoGen,height:selectedHeightForVideoGen} : null,
    minDimensionPixels: minDimensionPixels ? minDimensionPixels : null,
    language: {
       languageCode: selectedGenVideoLanguage ? selectedGenVideoLanguage?.languageCode : null,
       languageName: selectedGenVideoLanguage ? selectedGenVideoLanguage?.languageName : null
    },
  }




  const imageSettings = imageFormat || imageModel || imageRatio ?
        {
          outputFormat:imageFormat?imageFormat:null,
          style:imageModel?imageModel:null,
          aspectRatio:imageRatio?imageRatio:null
        }
      : null;

  const audioSettings = audioLanguage || audioVoice ? {
          voice:audioVoice? audioVoice:null,
          language:audioLanguage?audioLanguage:null,
  } : null ;

  const fileIds = threadFiles.map((file) => file.fileId);

  if (historyFiles && historyFiles.length > 0) {
    historyFiles.forEach(file => {
     fileIds.push(file.id);
    });
  }

  const currThreadMsg = {
    content: latestEntry.content,
    file_ids: fileIds,
    videoSettings: videoSettings,
    imageSettings:imageSettings,
    pptTemplateSettings: { templateId: templateId },
    audioSettings:audioSettings,
    ...(isValidDetails !== undefined && { isValidDetails: isValidDetails })
  }; 
  console.log("Sending message with isValidDetails:", isValidDetails);


  try {
    const response = await fetch(`${sourceUrl}/threads/thread-${req_target_threadId}/message`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization":`Bearer ${token}`
      },
      body: JSON.stringify(currThreadMsg),
    });
    
    const responseData = await response.json();
    // if(responseData.sender ==='modalX'){
    //   const content = responseData.content;
    //   const url = findUrl(content);
    //   const currMsg = {
    //     sender: "modalX",
    //     content: content,
    //     file: url ? { url: url, status: "processing" } : null,
    //   };
    //   return currMsg;
    // }
    PromptInputEvent("Prompt Input Event", "Prompt Input Search", currThreadMsg, responseData)
    if (responseData.status) {
      // ==========================Invoke matrix api ===================================================
        // Invoke matrix API
        await InvokeMatrixApi(responseData, response, 'POST', targetThreadId, 'message', currThreadMsg,email);
      // ===============================================================================================
      const content = responseData.data.content;
      // const url = findUrl(content);
      // const currMsg = {
      //   sender: "modalX",
      //   content: content,
      //   file: url ? { url: url, status: "processing" } : null,
      // };

      const urls = findUrls(content);
      // const currMsg = {
      //   sender: "modalX",
      //   content: content,
      //   file: urls ? { urls: urls, status: "processing" } : null,
      // };
      const currMsg = {
        sender: "modalX",
        content: content,
        status: "processing",
        file: urls && urls.length > 0 ? urls.map(url => ({ url: url, status: "processing" })) : null,
        id:responseData.data.id?responseData.data.id:null,
        action:responseData.data.action?responseData.data.action:null,
      };
      // alert("dnsfnjsknj")
      return currMsg;
    } else {
      // alert(email)
      // console.error("Failed to process messageeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee:", responseData?.error);
      await InvokeMatrixApi(responseData?.error, "", 'POST', targetThreadId, 'message', currThreadMsg,email);
      // PromptInputEvent("Prompt Input Event", "Prompt Input Search", currThreadMsg, responseData)
      return null;
    }
  } catch (error) {
    // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
      await InvokeMatrixApi(error, "", 'POST', targetThreadId, 'message', currThreadMsg,email);
    // ===============================================================================================
    ErrorEvent(error, error.message);
    return null;
  }
}



export async function checkUrlStatus(url, threadId) {
  console.log("here is the threadid and url ",threadId,url)
  const token = localStorage.getItem('token') ;
  const email = localStorage.getItem("userEmail");
  try {
    const response = await fetch(`${sourceUrl}/status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":`Bearer ${token}`
      },
      body: JSON.stringify({ threadId, url }),
    });
    

    const responseData = await response.json();
    // console.log("zzzzzzzzzzzzzzzzzzzz",responseData)
    if(response.status === 200){
      if (responseData.data.status === "completed" || responseData.data.status === "failed") {
        return true;
      } else  {
        return false;
      }
    }
    else if(response.status === 500){
      return null;
    }
    // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
      await InvokeMatrixApi(responseData, response, 'POST',threadId, 'status', {threadId,url,userId:email});
    // ===============================================================================================
    
  } catch (error) {
    // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
      await InvokeMatrixApi(error, error.data, 'POST',threadId, 'status', {threadId,url});
    // ===============================================================================================
    console.error("Check URL status request error:", error);
    return null;
  }
}

export const InvokeMatrixApi = async(jsonRespData,respData,reqType,threadId,endPoint,reqBody,setThreadId,emailId) => {
  // const targetThreadId = threadId || (await createThread(setThreadId)); 
  const targetThreadId = threadId || localStorage.getItem("threadId"); 
  try {
    const data = {
      url: endPoint,
      threadId: targetThreadId,
      requestId: jsonRespData.requestID,
      requestType: reqType,
      requestBody: reqBody,
      responseBody: jsonRespData,
      responseCode: respData.status,
      userId:emailId
    };
  
    const headers = {
      'Content-Type': 'application/json'
    };
  
    const matrix_Res = await axios.post(`${sourceUrl}/add`, data, { headers });
    // console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh",matrix_Res)
  } catch (error) {
    console.error('Error making the POST request:', error);
  }
}

export async function uploadFiles(fileObj, threadId, setThreadId) {
  const targetThreadId = threadId || (await createThread(setThreadId));
  const token = localStorage.getItem('token') ;
  if (!targetThreadId) {
    console.error("No threadId available for uploading files.");
    return null;
  }

  const formData = new FormData();
  if (fileObj.type === "link") {
    formData.append("link", fileObj.link);
  } else if (fileObj.type === "file") {
    formData.append("file", fileObj.file);
  }

  try {
    const response = await fetch(
      `${sourceUrl}/threads/fileupload?threadId=${targetThreadId}`,
      {
        method: "POST",
        headers: { 
          "Authorization":`Bearer ${token}`
        },
        credentials: "include",
        body: formData,
      }
    );
    const responseData = await response.json();
     // ==========================Invoke matrix api ===================================================
          const reqBody = {};
          if (fileObj.type === "link") {
            reqBody.link = fileObj.link;
          } else if (fileObj.type === "file") {
            reqBody.file = fileObj.file.name; // Pass the file name instead of the file object
          }

            // Invoke matrix API
          await InvokeMatrixApi(responseData, response, 'POST', targetThreadId, 'fileupload', reqBody);
     // ===============================================================================================
     console.log("response dataaaaaaaaaaaaaaa",responseData)
     return responseData ;
    // return await response.json(); // Return the entire response object
  } catch (error) {
    console.error("Upload file request Error:", error);
    // ==========================Invoke matrix api ===================================================
      const reqBody = {};
      if (fileObj.type === "link") {
        reqBody.link = fileObj.link;
      } else if (fileObj.type === "file") {
        reqBody.file = fileObj.file.name; // Pass the file name instead of the file object
      }

        // Invoke matrix API
      await InvokeMatrixApi(error, error.data, 'POST', targetThreadId, 'fileupload', reqBody);
    // ===============================================================================================
    return null;
  }
}



export async function upload_FilesOnly(fileObj, threadId, setThreadId) {
  const targetThreadId = threadId || (await createThread(setThreadId));
  const token = localStorage.getItem('token');

  if (!targetThreadId) {
    console.error("No threadId available for uploading files.");
    return null;
  }

  const formData = new FormData();
  if (fileObj.type === "link") {
    formData.append("link", fileObj.link);
  } else if (fileObj.type === "file") {
    formData.append("file", fileObj.file);
  }

  try {
    const response = await fetch(
      `${sourceUrl}/threads/upload?threadId=${targetThreadId}`,
      {
        method: "POST",
        headers: { 
          "Authorization": `Bearer ${token}`
        },
        credentials: "include",
        body: JSON.stringify({ name: fileObj.file.name }),
      }
    );

    const responseData = await response.json();

    // Check if the upload to /upload was successful
    if (responseData.status) {
      // Assign fileId to fileObj.file.fileId
      fileObj.file.fileId = responseData.data.id;

      // Upload the file to the provided S3 URL
      await uploadToS3(responseData.data.url, fileObj.file);

      // Update upload status and return its response
      const uploadStatusResponse = await uploadStatus(targetThreadId, fileObj.file);

      // Invoke matrix API with appropriate data
      const reqBody = {
        file: fileObj.file.name // Adjust as per your matrix API requirements
      };
      await InvokeMatrixApi(responseData, response, 'POST', targetThreadId, 'fileupload', reqBody);

      return uploadStatusResponse; // Return the response from uploadStatus
    } else {
      console.error("Failed to upload file:", responseData);
      return null;
    }
  } catch (error) {
    console.error("Upload file request Error:", error);

    // Invoke matrix API with error details
    const reqBody = {
      error: error.message // Example, adjust as per your matrix API requirements
    };
    await InvokeMatrixApi(error, error.data, 'POST', targetThreadId, 'fileupload', reqBody);

    return null;
  }
}

async function uploadToS3(url, file) {
  try {
    await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
        // 'x-amz-acl': 'public-read',
      }
    });
    // await fetch(url, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': file.type,
    //     // 'x-amz-acl': 'public-read', // Uncomment if needed
    //   },
    //   body: file // Directly use the file object as the body
    // });
    console.log("File uploaded to S3 successfully");
  } catch (error) {
    console.error("Error uploading file to S3:", error);
    throw error; // Handle or rethrow the error as needed
  }
}

async function uploadStatus(threadId, file) {
  const token = localStorage.getItem('token');
  try {
    // Call the uploadStatus API
    const statusResponse = await fetch(
      `${sourceUrl}/threads/uploadStatus?threadId=${threadId}`,
      {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: file.fileId,       // Use a unique identifier for the file, adjust if needed
          name: file.name,
          status: true         // Indicate status (true/false based on your need)
        })
      }
    );

    const statusData = await statusResponse.json();
    if (statusResponse.ok && statusData.status) {
      console.log("Upload status updated successfully:", statusData);
      return statusData;
    } else {
      console.error("Failed to update upload status:", statusData);
      return null;
    }
  } catch (error) {
    console.error("Error updating upload status:", error);
    throw error;
  }
}


export async function getUserInfo(){
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${sourceUrl}/user/info`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    const data = await response.json();
    localStorage.setItem('userId', JSON.stringify(data.data.id));
    mixpanel.identify(data.data.id)
}catch(err){
  console.log(err);
}
}




// export async function createThread(setThreadId) {
//   try {
//     const response = await fetch("https://www.modalx.ai/threads", {
//       method: "POST",
//       credentials: "include",
//       headers: { "Content-Type": "application/json" },
//     });

//     const responseData = await response.json();
//     if (responseData.status) {
//       setThreadId(responseData.data);
//       return responseData.data;
//     } else {
//       throw new Error("Thread creation failed");
//     }
//   } catch (error) {
//     console.error("Thread creation error:", error);
//     return null;
//   }
// }

// // Function to find the URL in the modalX response (temporary, #todo by backend in production)
// const findUrl = (msg) => {
//   const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
//   const match = urlRegex.exec(msg);
//   return match ? match[1] : null;
// };

// export async function sendMessage(
//   latestEntry,
//   threadId,
//   threadFiles,
//   threadSettings,
//   setThreadId
// ) {
//   const targetThreadId = threadId || (await createThread(setThreadId));
//   if (!targetThreadId) {
//     console.error("No threadId available for sending message.");
//     return;
//   }

//   const synthesiaVideoSettings =
//     threadSettings.synthesiaBackground ||
//     threadSettings.synthesiaAvatar ||
//     threadSettings.synthesiaVoice
//       ? {
//           avatarId: threadSettings.synthesiaAvatar.avatarId
//             ? threadSettings.synthesiaAvatar.avatarId
//             : null,
//           avatarVoiceId: threadSettings.synthesiaVoice.VoiceId
//             ? threadSettings.synthesiaVoice.VoiceId
//             : null,
//           backgroundImageId: threadSettings.synthesiaBackground.imageID
//             ? threadSettings.synthesiaBackground.imageID
//             : null,
//         }
//       : null;

//   const fileIds = threadFiles.map((file) => file.fileId);
//   const currThreadMsg = {
//     content: latestEntry.content,
//     file_ids: fileIds,
//     synthesiaVideoSettings: synthesiaVideoSettings,
//   };

//   try {
//     const response = await fetch(`https://www.modalx.ai/threads/${targetThreadId}/message`, {
//       method: "POST",
//       credentials: "include",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(currThreadMsg),
//     });

//     const responseData = await response.json();
    
//     if (responseData.status) {
//       const content = responseData.data.content;
//       const url = findUrl(content);
//       const currMsg = {
//         sender: "modalX",
//         content: content,
//         file: url ? { url: url, status: "processing" } : null,
//       };
//       return currMsg;
//     } else {
//       console.error("Failed to process message:", responseData.message);
//       return null;
//     }
//   } catch (error) {
//     console.error("Send message request error:", error);
//     return null;
//   }
// }



// export async function checkUrlStatus(url, threadId) {
//   try {
//     const response = await fetch("https://www.modalx.ai/status", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ threadId, url }),
//     });

//     const responseData = await response.json();
//     if (responseData.data.status === "completed") {
//       return true;
//     } else {
//       return false;
//     }
//   } catch (error) {
//     console.error("Check URL status request error:", error);
//     return null;
//   }
// }

// export async function uploadFiles(fileObj, threadId, setThreadId) {
//   const targetThreadId = threadId || (await createThread(setThreadId));
//   if (!targetThreadId) {
//     console.error("No threadId available for uploading files.");
//     return null;
//   }

//   const formData = new FormData();
//   if (fileObj.type === "link") {
//     formData.append("link", fileObj.link);
//   } else if (fileObj.type === "file") {
//     formData.append("file", fileObj.file);
//   }

//   try {
//     const response = await fetch(
//       `https://www.modalx.ai/threads/fileupload?threadId=${targetThreadId}`,
//       {
//         method: "POST",
//         credentials: "include",
//         body: formData,
//       }
//     );

//     return await response.json(); // Return the entire response object
//   } catch (error) {
//     console.error("Upload file request Error:", error);
//     return null;
//   }
// }
